import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import Logo from "../../images/logo.svg"
import styles from "../../scss/includes/atoms/_logo.module.scss"

const SiteLogo = ({ siteTitle }) => {
  return (
    <a href="/" title={siteTitle}>
      <figure className={`site-logo ${styles.logo}`}>
        <Logo />
      </figure>
    </a>
  )
}

SiteLogo.propTypes = {
  siteTitle: PropTypes.string,
}
export default SiteLogo
