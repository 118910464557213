import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/contact-icon.module.scss"
import Phone from "../../images/phone.svg"
import Mail from "../../images/mail.svg"
import LinkedIn from "../../images/linkedin.svg"
import ParseHTML from "../particles/ParseHTML"

const ContactIcon = ({ title, url, icon, target = "_self", content }) => {
  switch (icon.toLowerCase()) {
    case "phone":
      icon = <Phone />
      break
    case "mail":
      icon = <Mail />
      break
    case "linkedin":
      icon = <LinkedIn />
      break
    default:
      icon = ""
  }

  const html = () => {
    if (url) {
      return (
        <a
          href={url}
          title={title}
          target={target}
          className={`${styles.link}`}
        >
          <span className={`icon ${styles.icon}`}>{icon}</span>
          {title && (
            <span className={`title font-weight-semibold ${styles.title}`}>
              {title}
            </span>
          )}
          {content && (
            <span className={`content ${styles.content}`}>{content}</span>
          )}
        </a>
      )
    } else {
      return (
        <span className={`${styles.nolink}`}>
          <span className={`icon ${styles.icon}`}>{icon}</span>
          {title && (
            <span className={`title font-weight-semibold ${styles.title}`}>
              {title}
            </span>
          )}
          {content && (
            <span className={`content ${styles.content}`}>
              {ParseHTML(content)}
            </span>
          )}
        </span>
      )
    }
  }

  return <li className={`footer-icon ${styles.footerIcon}`}>{html()}</li>
}

ContactIcon.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.string.isRequired,
  target: PropTypes.string,
  content: PropTypes.string,
}

export default ContactIcon
