import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/menu-footer.module.scss"
import ParseHTML from "../particles/ParseHTML"
import ChevronDown from "../../images/chevron-down.svg"
import MenuLink from "../atoms/MenuLink"

const MenuFooter = ({ menu, current }) => {
  const links = menu.items

  //console.log(links)

  const hasChildren = item => {
    return hasSubMenu(item)
      ? ` has-sub-menu has-children ${styles.hasSubMenu}`
      : ``
  }

  const hasSubMenu = item => {
    return item && Array.isArray(item.childItems) && item.childItems.length > 0
  }

  const isCurrent = item => {
    return item.slug === current.replace(/\//g, "") ? styles.current : ""
  }

  const toggleSubMenu = e => {
    e.preventDefault()
    //e.target.classList.toggle(styles.subMenuOpen)
    //e.target.nextSibling.classList.toggle(styles.subMenuOpen)
    let parent = e.target.parentElement
    parent
      .getElementsByClassName("toggle-submenu")[0]
      .classList.toggle(styles.subMenuOpen)
    parent
      .getElementsByClassName("submenu")[0]
      .classList.toggle(styles.subMenuOpen)
  }

  const linkTag = link => {
    if (link.url === "#" && hasSubMenu(link)) {
      return <a onClick={toggleSubMenu}>{ParseHTML(link.title)}</a>
    } else {
      return <MenuLink {...link} />
    }
  }

  return (
    <nav className={`nav ${styles.nav}`}>
      <ul className="menu">
        {links.map(link => (
          <li
            className={`menu-item menu-item-${link.wordpress_id}${hasChildren(
              link
            )} ${isCurrent(link)}`}
            key={link.wordpress_id}
          >
            {linkTag(link)}
            {hasSubMenu(link) && (
              <span
                className={`toggle-submenu ${styles.toggle}`}
                onClick={toggleSubMenu}
              >
                <ChevronDown />
              </span>
            )}
            {hasSubMenu(link) && (
              <ul className={`submenu ${styles.subMenu}`}>
                {link.childItems.map(child => (
                  <li
                    className={`menu-item menu-item-${
                      child.wordpress_id
                    } ${isCurrent(child)}`}
                    key={child.wordpress_id}
                  >
                    <MenuLink {...child} />
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

MenuFooter.propTypes = {
  menu: PropTypes.object.isRequired,
  current: PropTypes.string,
}

MenuFooter.defaultProps = {
  menu: {},
}

export default MenuFooter
