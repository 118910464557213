import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, createRef } from "react"
import useWindowSize from '../particles/hooks/useWindowSize'
import useFontsReady from '../particles/hooks/useFontsReady'

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/menu.module.scss"
import ChevronDown from "../../images/chevron-down.svg"
import MenuLink from "../atoms/MenuLink"

const Menu = ({ menu, current }) => {
  const { width } = useWindowSize()
  const isMobile = width < 960
  const fontsReady = useFontsReady()
  const links = menu.items

  // Menu management

  const subMenuRefs = {}
  const [subMenuStates, setSubMenuStates] = useState(links.reduce((acc, l) => {
    acc[l.wordpress_id] = { open: false, height: 0 }
    return acc
  }, {}))

  const toggleSubMenu = useCallback((event, item) => {
    event.preventDefault()
    if (isMobile) {
      setSubMenuStates(states => {
        const id = item.wordpress_id
        const open = !states[id].open
        return { ...states, [id]: { ...states[id], open } }
      })
    }
  }, [isMobile, setSubMenuStates])

  const getMenuHeight = useCallback((open, subMenuHeight) => {
    return open ? `calc(8rem + ${subMenuHeight}px)` : '6.75rem'
  })

  useEffect(() => {
    setSubMenuStates(states => {
      for (const [id, ref] of Object.entries(subMenuRefs)) {
        states[id].height = ref.current.clientHeight
      }
      return states
    })
  }, [width, fontsReady, subMenuRefs, setSubMenuStates])

  // Class management

  const hasSubMenu = item => {
    return item && Array.isArray(item.childItems) && item.childItems.length > 0
  }
  const isOpen = item => {
    return hasSubMenu(item) && subMenuStates[item.wordpress_id].open
  }
  const isCurrent = item => {
    return item.slug === current.replace(/\//g, "")
  }

  const linkClasses = item => {
    const classes = ['menu-item', `menu-item-${item.wordpress_id}`]
    if (hasSubMenu(item)) {
      classes.push('has-sub-menu', 'has-children', styles.hasSubMenu)
    }
    if (isCurrent(item)) {
      classes.push(styles.current)
    }
    if (isOpen(item)) {
      classes.push(styles.subMenuOpen)
    }
    return classes.join(' ')
  }

  // Sub-components

  const linkTag = link => {
    if (link.url === "#" && hasSubMenu(link)) {
      return <a
        href={link.url}
        onClick={e => toggleSubMenu(e, link)}
      >{link.title}</a>
    } else {
      return <MenuLink {...link} />
    }
  }

  const mobileChevron = link => {
    const classes = ['toggle-submenu', styles.toggle]
    if (isOpen(link)) {
      classes.push(styles.subMenuOpen)
    }
    return (
      <span className={classes.join(' ')}>
        <ChevronDown />
      </span>
    )
  }

  const subMenu = (link, ref) => {
    const classes = ['submenu', styles.subMenu]
    if (isOpen(link)) {
      classes.push(styles.subMenuOpen)
    }
    return (
      <ul className={classes.join(' ')} ref={ref}>
        {link.childItems.map(child => (
          <li key={child.wordpress_id} className={linkClasses(child)}>
            <MenuLink {...child} />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <nav className={`nav ${styles.nav}`}>
      <ul className="menu">
        {links.map(link => {
          const id = link.wordpress_id
          let ref
          let style = {}
          if (hasSubMenu(link)) {
            ref = createRef()
            subMenuRefs[id] = ref
            // Mobile breakpoint
            if (isMobile) {
              style.height = getMenuHeight(isOpen(link), subMenuStates[id].height)
            }
          }
          return (
            <li key={id} className={linkClasses(link)} style={style}>
              {linkTag(link)}
              {hasSubMenu(link) && mobileChevron(link)}
              {hasSubMenu(link) && subMenu(link, ref)}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

Menu.propTypes = {
  menu: PropTypes.object.isRequired,
  current: PropTypes.string,
}

Menu.defaultProps = {
  menu: {},
}

export default Menu
