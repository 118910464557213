export const useRelativeMenu = url => {
  if (!url) return undefined
  const WPURL = process.env.WORDPRESS_BASE_URL
  let string = url
  if (url.startsWith(`/`)) return url
  string = string.replace(WPURL, "")

  return string
}

export default useRelativeMenu
