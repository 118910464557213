import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/link.module.scss"
import Chevron from "../../images/chevron.svg"
import useRelative from "../particles/hooks/useRelative"

const PageLink = ({ title, url, target = "_self" }) => (
  <a href={useRelative(url)} target={target} className={`link ${styles.link}`}>
    {title}
    <span className={styles.icon}>
      <Chevron />
    </span>
  </a>
)

PageLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
}

export default PageLink
