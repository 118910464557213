import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/_footer-icons.module.scss"
import ContactIcon from "../atoms/ContactIcon"
import { useSiteSocialData } from "../particles/hooks/useSiteSocialData"
const FooterIcons = ({ options }) => {
  const social = useSiteSocialData()
  const phone = options.phone
  const email = options.email
  const linkedIn = social.linkedIn.url

  return (
    <ul className={styles.icons}>
      {phone && (
        <ContactIcon url={`tel:${phone}`} icon={"phone"} title={phone} />
      )}
      {email && (
        <ContactIcon url={`mailto:${email}`} icon={"mail"} title={email} />
      )}
      {linkedIn && (
        <ContactIcon url={linkedIn} icon={"linkedin"} title="LinkedIn" />
      )}
    </ul>
  )
}

FooterIcons.propTypes = {
  options: PropTypes.object.isRequired,
}

export default FooterIcons
