import PropTypes from "prop-types"
import React from "react"
import { document } from "browser-monads"
/**
 * Internal components
 */
import styles from "../../scss/includes/atoms/hamburger.module.scss"

const Hamburger = ({ onClick, open }) => {
  let classes
  const body = document.body
  if (open) {
    body.classList.add("menu-open")
    classes = styles.open
  } else {
    body.classList.remove("menu-open")
  }

  return (
    <button onClick={onClick} className={`${styles.hamburger} ${classes}`}>
      <span className={styles.line} />
      <span className={styles.line} />
    </button>
  )
}

Hamburger.propTypes = {
  onClick: PropTypes.func,
}
export default Hamburger
