import React from "react"

const defaults = { baseWidth: 1920, baseHeight: 1080 }

export default function useWindowSize({ baseWidth, baseHeight } = defaults) {
  const ssr = typeof window === "undefined"

  const [windowSize, setWindowSize] = React.useState({
    width: ssr ? baseWidth : window.innerWidth,
    height: ssr ? baseHeight : window.innerHeight,
  })

  const updateSize = () =>
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })

  React.useEffect(() => {
    window.addEventListener("resize", updateSize)
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [])

  return windowSize
}
