import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/container.module.scss"

const Container = ({ children, className }) => (
  <div className={`container ${styles.container} ${className}`}>{children}</div>
)

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
Container.defaultProps = {
  className: "",
}

export default Container
