import { useStaticQuery, graphql } from "gatsby"

export const useSiteOptionsData = () => {
  const { allWordpressAcfOptions } = useStaticQuery(
    graphql`
      query ACFOptions {
        allWordpressAcfOptions {
          nodes {
            options {
              email
              phone
              tracking_code
              contact_endpoint
              latest_news
              allergist_finder
              bugheard_api_key
              google_analytics_id
              google_maps_api_key
              google_tag_manager_id
              disclaimer_content
              disclaimer_cookie_value
              disclaimer_cookie_expires
            }
          }
        }
      }
    `
  )

  return allWordpressAcfOptions.nodes
}
