import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/section.module.scss"

const Section = ({ children, className }) => (
  <section className={`section ${styles.section} ${className}`}>
    <div className="section__inner">{children}</div>
  </section>
)

Section.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Section
