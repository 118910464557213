import PropTypes from "prop-types"
import React, { useState } from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/header.module.scss"
import { useSiteMenuData } from "../particles/hooks/useSiteMenuData"
import Menu from "./Menu"
import SiteLogo from "../atoms/SiteLogo"
import Container from "./Container"
import Hamburger from "../atoms/Hamburger"

const Header = ({ currentPage }) => {
  const [menuOpen, toggleMenu] = useState(false)
  const menuData = useSiteMenuData()
  const headerMenu = menuData.find(menu => menu.node.name === "main-menu")

  const headerClasses = ['site-header', styles.header]
  const rightClasses = [styles.right]
  if (menuOpen) {
    headerClasses.push(styles.headerOpen)
    rightClasses.push(styles.open)
  }

  return (
    <header className={headerClasses.join(' ')}>
      <Container className="max-width-xxl">
        <div className={styles.inner}>
          <div className={styles.left}>
            <SiteLogo />
            <Hamburger onClick={() => toggleMenu(!menuOpen)} open={menuOpen} />
          </div>
          <div className={rightClasses.join(' ')}>
            <div className={`navigation ${styles.navigation}`}>
              <Menu menu={headerMenu.node} current={currentPage} />
            </div>
          </div>
        </div>
      </Container>
    </header>
  )
}

Header.propTypes = {
  currentPage: PropTypes.string,
}

export default Header
