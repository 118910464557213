export function decodeHTML(html) {
  if (!html) return null
  html = html.replace("amp;", "")
  return html.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec)
  })
}

export function httpTohttps(html) {
  if (!html) return null
  return html.replace("http://", "https://")
}

export function isInternal(url) {
  if (!url) return false
  if (url.startsWith("mailto:")) return false
  if (url.startsWith("tel:")) return false
  return !url.startsWith("http")
}

export function YouTubeGetID(url) {
  if (!url) return null
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_]/i)[0] : url[0]
}
