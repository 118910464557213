import { useStaticQuery, graphql } from "gatsby"

export const useSiteSocialData = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query SocialData {
        wordpress {
          seo {
            social {
              facebook {
                url
              }
              instagram {
                url
              }
              linkedIn {
                url
              }
              mySpace {
                url
              }
              pinterest {
                url
              }
              twitter {
                username
              }
              youTube {
                url
              }
            }
          }
        }
      }
    `
  )

  return wordpress.seo.social
}
