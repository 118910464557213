import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import Img from "gatsby-image"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/page-head.module.scss"
import ParseHTML from "../particles/ParseHTML"
import Arrow from "../../images/arrow-down.svg"

const PageHead = ({ content }) => {
  const header = () => {
    if (content.pretitle || content.title || content.content) {
      return (
        <header
          className="page-head__header"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="750"
        >
          {content.pretitle && (
            <p className={`${styles.pretitle} max-width-${content.maxWidth}`}>
              {content.pretitle}
            </p>
          )}
          {content.title && <h1 className={styles.title}>{content.title}</h1>}
          {content.content && (
            <div
              className={`${styles.content} max-width-${content.maxWidth}`}
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay="500"
              data-sal-duration="750"
            >
              {ParseHTML(content.content)}
            </div>
          )}
        </header>
      )
    }
  }

  //console.log('PageHead.content', content)
  const absPos = { position: "absolute" }

  return (
    <Section
      className={`page-head ${styles.section} height-${content.headerheight} section-space-${content.sectionSpacing}`}
      id={content.anchorId}
    >
      {content.image && (
        <Img
          className={`page-head__image ${styles.image}`}
          fluid={content.image.imageFile.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          style={absPos}
        />
      )}
      <div className={`page-head__content ${styles.contentWrapper}`}>
        <Container className={`max-width-xxl ${styles.container}`}>
          {header()}
        </Container>
      </div>
      <Arrow className={`animate-bob ${styles.arrow}`} />
    </Section>
  )
}

PageHead.propTypes = {
  content: PropTypes.object,
}

export default PageHead
