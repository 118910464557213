import PropTypes from "prop-types"
import React from "react"
import Container from "./../organisms/Container"
import Section from "./../organisms/Section"
import Img from "gatsby-image"

/**
 * Internal components
 */
import styles from "../../scss/includes/molecules/post-head.module.scss"
//import ParseHTML from '../particles/ParseHTML'
import IconBook from "../../images/book.svg"
import IconUser from "../../images/user.svg"

const PostHead = ({ content }) => {
  const icon = type => {
    let icon = ""
    switch (type.toLowerCase()) {
      case "article":
        icon = <IconBook className="icon icon-book" />
        break
      case "peer review":
        icon = <IconUser className="icon icon-user" />
        break
      default:
        icon = ""
    }
    return icon
  }

  const tags = tagArr => {
    let tagReturn = [],
      tag
    for (tag in tagArr) {
      tagReturn.push(
        <span key={tag}>
          {icon(tagArr[tag].name)} {tagArr[tag].name}
        </span>
      )
    }
    return tagReturn
  }

  // console.log("PostHead.content", content)
  const absPos = { position: "absolute" }

  return (
    <Section className={`post-head ${styles.section} section-space-m`}>
      <div className={`post-head__content ${styles.contentWrapper}`}>
        {content.featuredImage && (
          <div className={styles.imageWrapper}>
            <Img
              className={`page-head__image ${styles.image}`}
              fluid={content.featuredImage.node.imageFile.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              style={absPos}
            />
          </div>
        )}

        <Container className={`max-width-xl ${styles.container}`}>
          <header
            className="post-head__header"
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="750"
          >
            {content.title && <h1 className={styles.title}>{content.title}</h1>}
            {content.tags && (
              <div className={styles.tags}>{tags(content.tags.nodes)}</div>
            )}
          </header>
        </Container>
      </div>
    </Section>
  )
}

PostHead.propTypes = {
  content: PropTypes.object,
}

export default PostHead
