import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"

/**
 * Internal components
 */
import useRelativeMenu from "../particles/hooks/useRelativeMenu"
import ParseHTML from "../particles/ParseHTML"

const MenuLink = ({ url, title }) => (
  <Link to={useRelativeMenu(url)} activeClassName={"is-active"}>
    {ParseHTML(title)}
  </Link>
)

MenuLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default MenuLink
