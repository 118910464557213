import PropTypes from "prop-types"
import React from "react"

/**
 * Internal components
 */
import styles from "../../scss/includes/organisms/footer.module.scss"
import SiteLogo from "../atoms/SiteLogo"
import Container from "./Container"
import { useSiteOptionsData } from "../particles/hooks/useSiteOptionsData"
import FooterIcons from "../molecules/FooterIcons"
import PageLink from "../atoms/PageLink"

import { useSiteMenuData } from "../particles/hooks/useSiteMenuData"
import MenuFooter from "./MenuFooter"

const Footer = ({ siteName, currentPage }) => {
  const currentYear = new Date().getFullYear()
  const optionsData = useSiteOptionsData()
  const newsPage = optionsData[0].options.latest_news
  const options = optionsData[0].options

  const menuData = useSiteMenuData()
  const footerMenu = menuData.find(menu => menu.node.name === "footer-menu")

  return (
    <footer className={`siteFooter ${styles.siteFooter}`}>
      <div className="siteFooter__inner">
        <Container className="max-width-xxl">
          <div className={`siteFooter__top ${styles.siteFooter__top}`}>
            <div
              className={`siteFooter__top__left ${styles.siteFooter__top__left}`}
            >
              <SiteLogo siteTitle={siteName} />
            </div>
            <div
              className={`siteFooter__top__right ${styles.siteFooter__top__right}`}
            >
              <FooterIcons options={options} />
            </div>
          </div>
          <div className={`siteFooter__bottom ${styles.siteFooter__bottom}`}>
            {false && newsPage && (
              <div
                className={`siteFooter__bottom__left ${styles.siteFooter__bottom__left}`}
              >
                <p className="p paragraph-small font-weight-semibold siteFooter__copyright">
                  <PageLink
                    url={newsPage}
                    title={`Latest News from ${siteName}`}
                  />
                </p>
              </div>
            )}

            <div
              className={`siteFooter__bottom__left ${styles.siteFooter__bottom__left}`}
            >
              <MenuFooter menu={footerMenu.node} current={currentPage} />
            </div>

            {siteName && (
              <div className="siteFooter__bottom__right">
                <p className="p paragraph-small siteFooter__copyright">
                  Copyright &copy; {currentYear} {siteName} - All rights
                  reserved
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteName: PropTypes.string,
}

export default Footer
