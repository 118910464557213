import { useState, useEffect } from 'react'

export default function useFontsReady() {
	const [ready, setReady] = useState(false)
	useEffect(() => {
		document.fonts.ready.then(() => {
			setReady(true)
		})
	}, [])
	return ready
}
